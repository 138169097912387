import React                         from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>

const ResetFilters = ({ items, refine }) => {
  const handleReset = e => {
    e.stopPropagation()

    localStorage.removeItem('quantityFilter')
    refine(items)
  }

  if(items.length === 0) return null

  return <button className='link d-flex align-items-center' onClick={handleReset}>
    <CloseIcon /> Filters ({items.length})
  </button>
}

export default connectCurrentRefinements(ResetFilters)
