import React, { useEffect, useState } from 'react'
import { createConnector } from 'react-instantsearch-dom'
import I18n from '../../src/i18n.js.erb'

const getIndexId = ({ ais, multiIndexContext }) => multiIndexContext ? multiIndexContext.targetedIndex : ais.mainTargetedIndex

const connectValueBetween = createConnector({
  displayName: 'ValueBetween',

  getProvidedProps(props, searchState) {
    const attribute = props.attribute1 + '_' + props.attribute2
    const currentRefinement = searchState[attribute] || ''

    return {
      currentRefinement
    }
  },

  refine(props, searchState, nextRefinement) {
    const attribute = props.attribute1 + '_' + props.attribute2

    const newState = {...searchState}

    // if(props.exclusive) {
    //   for(const { attribute1, attribute2 } of props.exclusive) {
    //     if(props.attribute1 !== attribute1 && props.attribute2 !== attribute2)
    //       delete newState[attribute1 + '_' + attribute2]
    //   }
    // }

    newState[attribute] = nextRefinement ? parseInt(nextRefinement) : null

    return newState
  },

  getSearchParameters(searchParameters, props, searchState) {
    const attribute = props.attribute1 + '_' + props.attribute2

    // if(props.exclusive) {
    //   for(const { attribute1, attribute2 } of props.exclusive) {
    //     if(props.attribute1 !== attribute1 && props.attribute2 !== attribute2)
    //       searchParameters.removeNumericRefinement(attribute1).removeNumericRefinement(attribute2)
    //   }
    // }

    if(searchState[attribute])
      return searchParameters.addNumericRefinement(props.attribute1, '<=', searchState[attribute]).addNumericRefinement(props.attribute2, '>=', searchState[attribute])
    else
      return searchParameters.removeNumericRefinement(props.attribute1).removeNumericRefinement(props.attribute2)
  },

  cleanUp(props, searchState) {
    const attribute = props.attribute1 + '_' + props.attribute2

    const { [attribute]: omit, ...nextSearchState } = searchState

    return nextSearchState
  },

  getMetadata(props, searchState) {
    const attribute = props.attribute1 + "_" + props.attribute2

    return {
      id: attribute,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: searchState[attribute]
        ? [
          {
            label: `${searchState[attribute]}`,
            value: (nextState) => ({
              ...nextState,
              [attribute]: null
            })
          }
        ]
        : []
    }
  }
})

const ValueBetweenFilter = ({ currentRefinement, refine, label, placeholder = '', inputOnly = false, unit, className, asInputGroup = false, filterBy = null, ...props }) => {
  const changed = e => {
    if(e.target.value === '')
      localStorage.removeItem('quantityFilter')
    else
      localStorage.setItem('quantityFilter', e.target.value)

    refine(e.target.value)
  }

  useEffect(() => {
    const quantityFilter = localStorage.getItem('quantityFilter');
    if(quantityFilter) {
      refine(quantityFilter);
    }
  }, [])

  const [initialFilterBy, setInitialFilterBy] = useState(filterBy)

  const inputClassName = unit ? 'form-control is--left' : 'form-control is--left'
  const initialValue = currentRefinement || ''

  const input = <input
    type='text'
    name={'qty_' + label}
    value={initialValue}
    onChange={changed}
    className={inputClassName}
    placeholder={asInputGroup ? '' : placeholder}
  />

  if(initialFilterBy && filterBy !== initialFilterBy && initialValue) {
    setInitialFilterBy(filterBy)
    refine(initialValue)
  }

  if(inputOnly) return input

  const klassName = className ? 'form-group ' + className : 'form-group'

  if(unit) return <div className={klassName} {...props}>
    {!asInputGroup && <div className='input-group input-group--small'>
      {input}
      <div className='input-group-append'>
        <div className='input-group-text'>{unit}</div>
      </div>
    </div>}

    {asInputGroup &&
      <div className="input-group product-quantity-filter is--dark is--large">
        {input}
        <div className="input-group-append">
          <label style={{ width: 'min-content' }} className="input-group-text product-quantity-filter__label is--right clickable" for="product-quantity-filter-input">
            {I18n.t('frontend.offers_index.quantity_filter.required_quantity')}
          </label>
        </div>
      </div>
    }
  </div>

  return <div {...props}>
    {label && <label for={'qty_' + label}>{label}</label>}
    {input}
  </div>
}

export default connectValueBetween(ValueBetweenFilter)
