import React, { useState, useCallback } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import _ from 'lodash';
import AnimatedInput from '../common/AnimatedInput'

const MagnifierIcon = () => <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.0347 12.9002C18.8433 13.1533 19.7168 12.7028 19.842 11.8648C20.1248 9.97274 19.8545 8.02718 19.0465 6.26898C18.0236 4.0432 16.2116 2.27583 13.961 1.30871C11.7105 0.34158 9.18118 0.243415 6.86242 1.0332C4.54365 1.82298 2.60015 3.44459 1.4078 5.58439C0.215457 7.7242 -0.141003 10.2301 0.407363 12.6176C0.955729 15.005 2.36996 17.1042 4.37652 18.5092C6.38308 19.9143 8.8394 20.5253 11.2704 20.2241C13.1907 19.9862 14.9874 19.1924 16.4477 17.9564C17.0944 17.4091 17.0179 16.4292 16.3773 15.8748C15.7366 15.3203 14.7753 15.4078 14.092 15.9089C13.1584 16.5935 12.0588 17.0348 10.8931 17.1792C9.21576 17.387 7.52091 16.9654 6.13639 15.9959C4.75187 15.0265 3.77606 13.578 3.39769 11.9307C3.01932 10.2834 3.26527 8.5543 4.08799 7.07785C4.9107 5.60139 6.25171 4.48249 7.85165 3.93754C9.45159 3.39259 11.1968 3.46033 12.7497 4.12764C14.3026 4.79496 15.5528 6.01443 16.2586 7.55021C16.7491 8.61744 16.9524 9.78471 16.8614 10.9389C16.7948 11.7835 17.2262 12.6471 18.0347 12.9002Z" fill="white"/>
  <path d="M14.229 17.9231L16.2502 15.7984L25.2802 24.3886C25.8669 24.9468 25.89 25.8749 25.3319 26.4616C24.7738 27.0483 23.8457 27.0715 23.2589 26.5133L14.229 17.9231Z" fill="white"/>
</svg>

const SearchBox = ({ currentRefinement, refine, ...props }) => {
  const [ value, setValue ] = useState(currentRefinement)

  const debouncedRefine = useCallback(_.debounce(refine, 500, {leading: true, trailing: true, maxWait: 1000}), [])

  const delayedScrollToTop = _.debounce(() => {
    const elements = document.querySelectorAll('.card__product');
    let shouldScroll = false;
    elements.forEach((element) => {
      const { top } = element.getBoundingClientRect();
      if (top < 0 || top > window.innerHeight) {
        shouldScroll = true;
      }
    });

    if (shouldScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, 200);

  const changed = e => {
    if(e.target.value.length < value.length) {
      setValue(e.target.value);
      refine(e.target.value);
    } else {
      setValue(e.target.value);
      debouncedRefine(e.target.value);
    }
    delayedScrollToTop();
  };

  return <div className='marketplace-search-box'>
    <AnimatedInput value={value} type='text' autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' maxLength='512' onChange={changed} className='form-control offers-index-search' {...props} />
    <div className="marketplace-search-box__magnifier">
      <MagnifierIcon />
    </div>
  </div>
}

export default connectSearchBox(SearchBox)
